import { useEffect, useState } from "react";
import "./salarycalculator.style.scss";


export const SalaryCalculator: React.FC = ({}) => {
  const defaultHourlyRate = 1350;
  const defaultHoursPerMonth = 157;
  const defaultMonthlyEarning = defaultHourlyRate * defaultHoursPerMonth;
  const defaultMonthlyPay = defaultMonthlyEarning * 0.6;
  const defaultVacationMoneyAmount = defaultMonthlyPay * 0.11;
  const defaultMontlySalary = defaultMonthlyPay - defaultVacationMoneyAmount;
  const defaultYearlyPay =
    defaultMonthlyPay * 11 + defaultVacationMoneyAmount * 11;

  const [hourlyRate, setHourlyRate] = useState<number>();
  const [hoursPerMonth, setHoursPerMonth] = useState<number>();
  const [monthlyEarnings, setMonthlyEarning] = useState<number>(
    defaultMonthlyEarning
  );
  const [monthlyPay, setMonthlyPay] = useState<number>(defaultMonthlyPay);
  const [monthlySalary, setMonthlySalary] =
    useState<number>(defaultMontlySalary);
  const [vacationMoneyAmount, setVacationMoneyAmount] = useState<number>(
    defaultVacationMoneyAmount
  );

  const [yearlyPay, setYearlyPay] = useState<number>(defaultYearlyPay);

  useEffect(() => {
    var currentHourlyRate = hourlyRate ?? defaultHourlyRate;
    currentHourlyRate = isNaN(currentHourlyRate)
      ? defaultHourlyRate
      : currentHourlyRate;
    var currentHoursPerMonth = hoursPerMonth ?? defaultHoursPerMonth;
    currentHoursPerMonth = isNaN(currentHoursPerMonth)
      ? defaultHoursPerMonth
      : currentHoursPerMonth;

    console.log(currentHourlyRate, "currentHourlyRate");
    console.log(hourlyRate, "hourlyRate");
    const newMonthlyEarning = currentHourlyRate * currentHoursPerMonth;
    const newMonthlyPay = newMonthlyEarning * 0.6;
    const newVacationMoneyAmount = newMonthlyPay * 0.12;
    const newMontlySalary = newMonthlyPay - newVacationMoneyAmount;
    const newYearlyPay = newMontlySalary * 11 + newVacationMoneyAmount * 11;
    setMonthlyPay(newMonthlyPay);
    setMonthlyEarning(newMonthlyEarning);
    setMonthlySalary(newMontlySalary);
    setVacationMoneyAmount(newVacationMoneyAmount);
    setYearlyPay(newYearlyPay);
  }, [hourlyRate, hoursPerMonth]);

  const formatter = new Intl.NumberFormat("nb-NB", {
    style: "currency",
    currency: "NOK",
    maximumFractionDigits: 0,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div id="salary-calculator">
      <section className="section">
        <h1 className="section__header">Lønnskalkulator</h1>
        <h5 className="section__ingress">
          Som ansatt på provisjon hvor du får 60% av det du fakturerer i lønn:
        </h5>
        <div className="salery-calculator">
          <div className="calculator-input">
            <div className="">Hvis du har en timepris på kr.&nbsp;</div>

            <input
              onChange={(d) => setHourlyRate(parseInt(d.target.value))}
              type="number"
              id="price"
              aria-label="price"
              className="inline inline-input__input undefined"
              placeholder={defaultHourlyRate.toString()}
              maxLength={4}
              aria-autocomplete="none"
              autoComplete="on"
              autoCorrect="false"
              style={{ width: "54px" }}
            />
            <div className="large-text">&nbsp;og jobber&nbsp;</div>

            <input
              onChange={(d) => setHoursPerMonth(parseInt(d.target.value))}
              type="number"
              id="numHours"
              aria-label="numHours"
              className="inline inline-input__input undefined"
              placeholder={defaultHoursPerMonth.toString()}
              maxLength={3}
              aria-autocomplete="none"
              autoComplete="on"
              autoCorrect="false"
              style={{ width: "40px" }}
            />
            <div className="large-text">&nbsp;timer pr. mnd:&nbsp;</div>
          </div>
          <div className="calculation-result-row">
            <div className="calculator-result">
              <button
                aria-label="113545 - Bruttolønn pr. måned, eks. feriepenger"
                className="calculator-result__title"
              >
                <span>{formatter.format(monthlySalary)}</span>
              </button>
              <div className="calculator-result__title"></div>
              <div className="calculator-result__description">
                Bruttolønn pr. måned, eks. feriepenger
              </div>
            </div>
            <div className="calculator-result">
              <button
                aria-label="1398870 - [object Object]"
                className="calculator-result__title"
              >
                <span>{formatter.format(yearlyPay)}</span>
              </button>
              <div className="calculator-result__title"></div>
              <div className="calculator-result__description">
                <div>
                  <div>Ca årslønn med 5 ukers ferie</div>
                  <div>11*mnd lønn + 11*oppsparte feriepenger</div>
                </div>
              </div>
            </div>
          </div>
          <div className="calculator-input__arrow"></div>
          <div className="calculation-result-row">
            <div className="calculator-result">
              <button
                aria-label="211950 - Bruttoinntjening pr. måned"
                className="calculator-result__title"
              >
                <span>
                  <span>{formatter.format(monthlyEarnings)}</span>
                </span>
              </button>
              <div className="calculator-result__title"></div>
              <div className="calculator-result__description">
                Bruttoinntjening pr. måned
              </div>
            </div>
            <div className="calculator-result">
              <button
                aria-label="127170 - Du får 60% av din inntjening pr. måned"
                className="calculator-result__title"
              >
                <span>{formatter.format(monthlyPay)}</span>
              </button>
              <div className="calculator-result__title"></div>
              <div className="calculator-result__description">
                Du får 60% av din inntjening pr. måned
              </div>
            </div>
            <div className="calculator-result">
              <button
                aria-label="13625 - Avsatte feriepenger pr. måned 12%"
                className="calculator-result__title"
              >
                <span>{formatter.format(vacationMoneyAmount)}</span>
              </button>
              <div className="calculator-result__title"></div>
              <div className="calculator-result__description">
                Avsatte feriepenger pr. måned 12%
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
